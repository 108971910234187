import React from "react";
import classes from "./Contacts.module.css";
import {CASTLE_NAIL_INFO} from "../../utils/Const";
import {images} from "../../assets/img";
import BookNowBtn from "../BookNowBtn";

const Contacts = (props) => {
    return (
        <div ref={props?.contactsRef} className={classes.contacts}>
            <div className={classes.contactsContainer}>
                <h1>{"Contact Us"}</h1>

                <a
                    href={`tel:${CASTLE_NAIL_INFO.tel}`}
                >{`Hotline: ${CASTLE_NAIL_INFO.phone}\n\n`}</a>
                <a
                    href={`mailto:${CASTLE_NAIL_INFO.email}`}
                >{`Email: ${CASTLE_NAIL_INFO.email}`}</a>

                <div>
                    <a
                        target={"_blank"}
                        href={CASTLE_NAIL_INFO.instagram}
                        rel="noreferrer"
                    >
                        <img
                            className={classes.contactsContainerImg}
                            alt={"insta"}
                            src={images.instaIcon}
                        />
                    </a>
                    <a target={"_blank"} href={CASTLE_NAIL_INFO.facebook} rel="noreferrer">
                        <img className={classes.contactsContainerImg} alt={"facebook"} src={images.fbIcon}/>
                    </a>
                </div>

                <BookNowBtn style={classes.bookNowBtn}/>
            </div>

            {/*<iframe*/}
            {/*    className={classes.iframe}*/}
            {/*    title="nail-map"*/}
            {/*    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2487.0755554879356!2d-0.056297!3d51.4384063!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487603d173360db3%3A0x8680490ce90aee99!2sGloss%20Nails%20Salon!5e0!3m2!1svi!2s!4v1687518645338!5m2!1svi!2s"*/}
            {/*    allowFullScreen=""*/}
            {/*    loading="lazy"*/}
            {/*    referrerPolicy="no-referrer-when-downgrade"*/}
            {/*/>*/}

            <iframe
                className={classes.iframe}
                title="nail-map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2193.640844323408!2d-2.8889095999999994!3d56.646040899999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48866371d24f4e7d%3A0xad924582c1e42690!2sCastle%20Nails%20Bar!5e0!3m2!1sen!2s!4v1696581274634!5m2!1sen!2s"
                // width="600" height="450" style="border:0;"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
    );
};

export default Contacts
