import classes from "./PrimaryBanner.module.css";
import React, { useContext, useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AuthContext from "../../store/auth-context";
import remoteConfig from "../../services/RemoteConfig";
import Helpers from "../../utils/Helpers";
import { t } from "../../locales/i18n";
import FadeIn from "../FadeIn";
import Carousel from "../Carousel/Carousel";
import { images } from "../../assets/img";
import BookNowBtn from "../BookNowBtn";

const PrimaryBanner = (props) => {
  const {} = props || {};

  useEffect(() => {}, []);

  return (
    <div className={classes.primaryBanner}>
      <img className={classes.primaryBannerImage} alt={"Primary Banner"} src={images.castleNailBanner} />
      <div className={classes.textContainer}>
        <h2>The go to salon in Scotland</h2>
        <BookNowBtn/>
        </div>
    </div>
  );
};

export default PrimaryBanner;
