const isDevelopment = process.env.NODE_ENV === 'development'

const parseJson = str => {
    let result;
    try {
        result = JSON.parse(str);
    } catch (e) {
        return str;
    }
    return result;
};

const isArrayAvailable = (arr)=>{
    return arr && Array.isArray(arr) && arr.length >0
}

const isDev = process.env.NODE_ENV === 'development';


export default {parseJson,isDevelopment,isArrayAvailable,isDev}
