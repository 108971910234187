import {images} from "../assets/img";

export const DENTAL_INFO = {
  ggMapUrl: "https://goo.gl/maps/pxHioJqP7W77BGce9",
  address: "699 QL22, TT. Củ Chi, Củ Chi, Thành phố Hồ Chí Minh, Việt Nam",
  email: "nhakhoabacsihuy@gmail.com",
  tel: "0856539999",
  phone: "0856 539 999",
  facebook: "https://www.facebook.com/nhakhoabacsibalongan/",
  instagram: "https://www.instagram.com",
};

export const CASTLE_NAIL_INFO = {
  ggMapUrl: "https://maps.app.goo.gl/f3R7dEFVXVGd3VcU7",
  address: "80, Castle nail Bar, Forfar DD8 3AA",
  email: "alana1686amelia@gmail.com",
  tel: "+447533283398",
  phone: "+44 753 328 3398",
  facebook: "https://www.facebook.com/profile.php?id=100040759615721",
  instagram: "https://www.instagram.com/castle_nails_bar/",
};

export const SERVICES = [
  {
    name:"Manicures",
    description:"We have a huge of OPI and Gel Bottle nail polish colours for you to choose at our nails salon in Angus. You can come and choose whatever you want for your bespoke nails art.",

    image: images.nailService
  },
  {
    name:"Pedicures",
    description:"At our nails bar in Angus, your feet will be massage, soles softened, and nails shape before being topped off with a colour of your choice. Your feet deserve to be pampered too.",
    image: images.nailService2
  },
  {
    name:"Technicians",
    description:"Our highly qualified team of nail technicians will take care your nails the ins and outs during your experiences at our nails bar in Forfar. We always welcome you with a warm smile and make you feel at home.",
    image: images.nailService3
  },
  {
    name:"Nails Art",
    description:"From classic to outstanding, our talented technicians can advise you the bespoke nail art of your imagine. Come here and enjoy your amazing nails art at our local shop in Angus.",
    image: images.nailService4
  }
]

export const LANGUAGE_ID = {
  vi:'vi',
  en:'en'
}
