import React from "react";
import classes from "./Header.module.css";
import { useTranslation } from "react-i18next";
import { CASTLE_NAIL_INFO } from "../../utils/Const";
import { images } from "../../assets/img";
import { t } from "../../locales/i18n";
import BookNowBtn from "../BookNowBtn";
import { useNavigate } from "react-router-dom";
import useCheckIsMobile from "../../hook/useCheckIsMobile";
import Menu from "../Menu/";

const Header = (props) => {
  const {
    introductionRef,
    contactsRef,
    serviceRef,
    bookingRef,
    onPressMainLogo,
    introductionPress,
    servicePress,
    contactPress,
    bookingPress,
  } = props || {};
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useCheckIsMobile();

  const scrollToSection = (ref) => () => {
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const renderLogo = () => {
    return (
      <img
        onClick={onPressMainLogo}
        className={`${classes.introLogo} ${
          onPressMainLogo ? classes.clickableLogo : null
        }`}
        alt={"tooth icon"}
        src={images.castleNailIcon}
      />
    );
  };

  return (
    <div className={classes.introHeader}>
      {!isMobile && renderLogo()}

      {isMobile ? (
        <Menu
          introductionRef={introductionRef}
          serviceRef={serviceRef}
          contactsRef={contactsRef}
        />
      ) : (
        <div className={classes.menuItem}>
          <h4 onClick={introductionPress || scrollToSection(introductionRef)}>
            {t("header.introduction")}
          </h4>
          <h4 onClick={servicePress || scrollToSection(serviceRef)}>
            {t("header.service")}
          </h4>
          <h4 onClick={() => navigate("/booking")}>{"Booking"}</h4>
          <h4 onClick={contactPress || scrollToSection(contactsRef)}>
            {t("header.contact")}
          </h4>
        </div>
      )}

      {isMobile && renderLogo()}

      <div className={classes.bookNow}>
        <a
          className={classes.bookNowA}
          href={`tel:${CASTLE_NAIL_INFO.tel}`}
        >{`${CASTLE_NAIL_INFO.phone}`}
        </a>

        <BookNowBtn style={classes.bookNowBtnHeader} isShowArrow={!isMobile} />
      </div>
    </div>
  );
};

export default Header;
