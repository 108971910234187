import React, { useEffect, useState } from "react";
import Helpers from "../../utils/Helpers";
import classes from "./InstagramFeed.module.css";
import { images } from "../../assets/img";
import { CASTLE_NAIL_INFO } from "../../utils/Const";
import useCheckIsMobile from "../../hook/useCheckIsMobile";

const MOCK_DATA = {
  0: [
    {
      permalink: "https://www.instagram.com/p/CyDIXoNMfVO/",
      mediaUrl:
        "https://scontent.cdninstagram.com/v/t51.29350-15/386581435_722211349737396_2221596512363143513_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=sG-R3GpPPIoAX_oOr78&_nc_ht=scontent.cdninstagram.com&edm=ANQ71j8EAAAA&oh=00_AfDBQeP7f3NsMo1atrxIwgbRnBhP9oQNxRgcULxiL9zqzw&oe=65259BAC",
    },
    {
      permalink: "https://www.instagram.com/p/CyApWJVMmQO/",
      mediaUrl:
        "https://scontent.cdninstagram.com/v/t51.29350-15/385835884_1812078562582611_863348071897850426_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=xFF8IbgigcsAX_ARvjS&_nc_ht=scontent.cdninstagram.com&edm=ANQ71j8EAAAA&oh=00_AfCdsToXr667D4eBQqVpF-Vw1LTVI8Lgq7trhkgRbvMNkg&oe=652435B3",
    },
    {
      permalink: "https://www.instagram.com/p/Cx9-p98MmD4/",
      mediaUrl:
        "https://scontent.cdninstagram.com/v/t51.29350-15/386346988_848683060030870_7751777583755663528_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=Gk5fDqzjLDMAX9jTIlh&_nc_ht=scontent.cdninstagram.com&edm=ANQ71j8EAAAA&oh=00_AfClBBVmFBZzofNlruoEnHNdx4d1b2IR6fRQdjxp6gEJTg&oe=65257FA5",
    },
    {
      permalink: "https://www.instagram.com/p/Cx7bDbKsYsF/",
      mediaUrl:
        "https://scontent.cdninstagram.com/v/t51.29350-15/385127895_1243856262974420_3350129377794387617_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=z8tphUhLkb8AX9GP4ki&_nc_oc=AQl3h2aWXXEwDbsE2Y66Zc5PYfsh8ZfBXSuVJxYeISNzmQ2nuCXCCQBcN2Afrtnmxh8&_nc_ht=scontent.cdninstagram.com&edm=ANQ71j8EAAAA&oh=00_AfBOH0fhpc71Fk8aG-rfhYK70ra7pf2iebLRWVlFxi0RNA&oe=65241A1E",
    },
  ],
  1: [
    {
      permalink: "https://www.instagram.com/p/Cx40qvuMzOf/",
      mediaUrl:
        "https://scontent.cdninstagram.com/v/t51.29350-15/386201701_851061539838874_4200592922515227182_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=eYsWT_UFPTMAX9rBzLt&_nc_ht=scontent.cdninstagram.com&edm=ANQ71j8EAAAA&oh=00_AfCvgDN5zxWVIvUP2Vzw2VlJXJxGNbwild9mFdgTtJVL6A&oe=652553E1",
    },
    {
      permalink: "https://www.instagram.com/p/Cxzo3I-symX/",
      mediaUrl:
        "https://scontent.cdninstagram.com/v/t51.29350-15/385677450_1391617245120973_1732102008519450184_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=1zzYqWtsi4QAX-OBSYk&_nc_ht=scontent.cdninstagram.com&edm=ANQ71j8EAAAA&oh=00_AfAR0l683kIwlSjeR9Y7QxpBj0X7W_PV96c3pviMGN-1hQ&oe=65254C31",
    },
    {
      permalink: "https://www.instagram.com/p/CxxEoZosmW0/",
      mediaUrl:
        "https://scontent.cdninstagram.com/v/t51.29350-15/384121202_728189385798938_3797600667468484979_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=FBCXMVKckAwAX_ztQ5B&_nc_ht=scontent.cdninstagram.com&edm=ANQ71j8EAAAA&oh=00_AfC3m8NDpXSHP0JPB0M_kuRoW9cdcUcagJS7kQrMDE59Xw&oe=65245748",
    },
    {
      permalink: "https://www.instagram.com/p/CxuhXcJsQ5C/",
      mediaUrl:
        "https://scontent.cdninstagram.com/v/t51.29350-15/383240539_1726319884461233_488104953123270099_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=1N6b2Ivozg4AX-v9dHU&_nc_ht=scontent.cdninstagram.com&edm=ANQ71j8EAAAA&oh=00_AfBNxFPDiTUwGgah9LjAAkJCDLQKHrj4OvVFQ0WLJc9GUg&oe=6523E0C0",
    },
  ],
};
const MOCK_LOCAL_DATA = {
  0: [
    {
      permalink: "https://www.instagram.com/castle_nails_bar/",
      mediaUrl:
         images.nailImg06,
    },
    {
      permalink: "https://www.instagram.com/castle_nails_bar/",
      mediaUrl:
      images.nailImg07  },
    {
      permalink: "https://www.instagram.com/castle_nails_bar/",
      mediaUrl:
      images.nailImg08  },
    {
      permalink: "https://www.instagram.com/castle_nails_bar/",
      mediaUrl:
      images.nailImg09 },
  ],
  1: [
    {
      permalink: "https://www.instagram.com/castle_nails_bar/",
      mediaUrl:
      images.nailImg10 },
    {
      permalink: "https://www.instagram.com/castle_nails_bar/",
      mediaUrl:
      images.nailImg11 },
    {
      permalink: "https://www.instagram.com/castle_nails_bar/",
      mediaUrl:
      images.nailImg12  },
    {
      permalink: "https://www.instagram.com/castle_nails_bar/",
      mediaUrl:
      images.nailImg13  },
  ],
};
const MOBILE_MOCK_DATA = {
  0: [
    {
      permalink: "https://www.instagram.com/castle_nails_bar/",
      mediaUrl:
      images.nailImg06,
    },
    {
      permalink: "https://www.instagram.com/castle_nails_bar/",
      mediaUrl:
      images.nailImg07
    },
  ],
  1: [
    {
      permalink: "https://www.instagram.com/castle_nails_bar/",
      mediaUrl:
      images.nailImg08  },
    {
      permalink: "https://www.instagram.com/castle_nails_bar/",
      mediaUrl:
      images.nailImg09 }
  ],
  2: [
    {
      permalink: "https://www.instagram.com/castle_nails_bar/",
      mediaUrl:
      images.nailImg10 },
    {
      permalink: "https://www.instagram.com/castle_nails_bar/",
      mediaUrl:
      images.nailImg11 },
  ],
  3: [
    {
      permalink: "https://www.instagram.com/castle_nails_bar/",
      mediaUrl:
      images.nailImg12  },
    {
      permalink: "https://www.instagram.com/castle_nails_bar/",
      mediaUrl:
      images.nailImg13  },
  ],
};

const ACCESS_TOKEN =
  "IGQWRPajJLeFNXcWs2R3NjV1RnZAnRUZAjhGRlV5WW1PS2dlYUgxc2NVOTlXcHI3TVBzSUNlN2xoZA0FNMmZAyQXdKNEVTQ3doc2VkeDZA1QTJFVlBzclh4Qkg1S3RhVHNEVWlPd3lwRFRXYzk5NmI4T2RKN1ctLVUwRnMZD";
const USER_ID = "24125941530353577";
const MAXIMUM_IMAGES_FETCHED = 8;

const InstagramFeed = () => {
    const isMobile = useCheckIsMobile();
  const [instagramFeed, setInstagramFeed] = useState(isMobile ? MOBILE_MOCK_DATA : MOCK_LOCAL_DATA);
  const NUMBER_ITEMS_PER_ROW = isMobile ? 2 : 4;

  useEffect(() => {
    const fetchMedia = async (id) => {
      const mediaUrl = `https://graph.instagram.com/${id}?access_token=${ACCESS_TOKEN}&fields=media_url,permalink`;
      const res = await fetch(mediaUrl);
      const json = await res?.json();
      return { permalink: json?.permalink, mediaUrl: json?.media_url };
    };

    const doFetch = async () => {
      const res = await fetch(
        `https://graph.instagram.com/${USER_ID}/media?access_token=${ACCESS_TOKEN}`
      );
      const json = (await res?.json())?.data;

      const fetchedItems = [];
      if (json?.length > 0) {
        for (
          let i = 0;
          i < json?.length && fetchedItems.length < MAXIMUM_IMAGES_FETCHED;
          i++
        ) {
          const item = json[i];
          const itemId = item.id;
          const instaItem = await fetchMedia(itemId);

          if (!instaItem.mediaUrl.includes("video")) {
            fetchedItems.push(instaItem);
          }
        }

        //Split array into group of NUMBER_ITEMS_PER_ROW
        const grouped = await fetchedItems.reduce((acc, post, ind) => {
          const index = parseInt(ind / NUMBER_ITEMS_PER_ROW);
          acc[index] = acc[index] || [];
          acc[index].push(post);
          return acc;
        }, {});

        setInstagramFeed(grouped);
      }
    };

    // NOTE: PREVENT FETCHING ON DEV, REMOVE CONDITION TO SEE THE LOGIC RUN
    //TODO: UNCOMMENT THESE 3 LINES TO FETCH IMG FROM INSTAGRAM ELSE USE LOCAL IMG
    if (!Helpers.isDev) {
      doFetch();
    }

  }, [NUMBER_ITEMS_PER_ROW]);

  if (!instagramFeed) {
    return null;
  }

  const openInstagram = () => {
    window.open(CASTLE_NAIL_INFO.instagram, "_blank");
  };

  return (
    <div className={classes.container}>
      <div className={classes.instaIconContainer} onClick={openInstagram}>
        <img
          src={images.instaIcon}
          alt={"instagram-icon"}
          className={classes.instaIcon}
        />
        <h3>castle_nails_bar</h3>
      </div>

      {!!instagramFeed &&
        Object.keys(instagramFeed).map((row, index) => {
          return (
            <div
              key={index}
              className={`${classes.feedContainer} ${
                index === (isMobile ? 0 : 1) ? classes.noMargin : ""
              }`}
            >
              {Helpers.isArrayAvailable(instagramFeed[row])
                ? instagramFeed[row].map((item, index2) => {
                    return (
                      <img
                        key={`${index2}_${item?.mediaUrl?.toString()}`}
                        onClick={() => window.open(item?.permalink, "_blank")}
                        className={`${classes.feedImage}`}
                        alt={item?.mediaUrl?.toString()}
                        src={item?.mediaUrl}
                      />
                    );
                  })
                : null}
            </div>
          );
        })}

      <div onClick={openInstagram} className={classes.followMeContainer}>
        <img
          alt={"instagram-icon"}
          src={images.instaIcon}
          className={classes.instaIconSmall}
        />
        <h4>Follow us on Instagram</h4>
      </div>
    </div>
  );
};

export default InstagramFeed;
