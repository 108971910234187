// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {fetchAndActivate, getRemoteConfig, getString} from "firebase/remote-config";
import React from "react";
import Helpers from "../utils/Helpers";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBQD53b5laevMFFJI6cjOrlbARVMvgB3Tk",
    authDomain: "nhakhoa-bacsi-huy.firebaseapp.com",
    projectId: "nhakhoa-bacsi-huy",
    storageBucket: "nhakhoa-bacsi-huy.appspot.com",
    messagingSenderId: "366135591081",
    appId: "1:366135591081:web:1a094eb9008b76c65ce9b8",
    measurementId: "G-HCPGCHDQP7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Remote Config and get a reference to the service
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = Helpers.isDevelopment ? 60000 : 1000000;

const fetchAndActivateRemoteConfig = () => {
    return new Promise((resolve, reject) => {
        fetchAndActivate(remoteConfig)
            .then((a) => {
                resolve(a)
                console.log('Firebase fectch and activate successfully!')
            })
            .catch((err) => {
                reject(err)
                console.log('Firebase fectch and activate FAILED!')
            });
    })
}

const getConfigValue = (key) => {
    return Helpers.parseJson(getString(remoteConfig, key))
}

export default {remoteConfig, fetchAndActivateRemoteConfig, getConfigValue};
