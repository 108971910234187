import classes from "./Introduction02.module.css";
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {images} from "../../assets/img";
import BookNowBtn from "../BookNowBtn";

//ABOUT US
const Introduction02 = (props) => {
    const {introductionRef} = props || {};

    return (
        <div ref={introductionRef} className={classes.introduction}>
            <div className={classes.rowWrapper}>
                <div className={classes.introTextContainer}>
                    <div>
                        <h2>Discover Your Bespoke Nails Bar In Angus</h2>
                        <p>Customer care is our number one priority at Castle Nails Bar Forfar, Angus. We want every
                            client to leave our salon with the perfect manicure or pedicure and a smile on their face.
                            Our friendly and knowledgeable team of nail technicians have been cherry-picked and are
                            trained to deliver a bespoke, professional experience from start to finish. Ensuring clients
                            can expect the highest quality treatments available in Angus.</p>
                    </div>

                    <div>
                        <h2>Our Services Guarantee</h2>
                        <p>At our nails salon, we aim to deliver a flawless nail treatment every time. If you experience
                            any problems with your gel mani or pedi from our salon, we offer complimentary nail repair.
                            Simply give us a call via Hotline 07447922382 (our manager) and our technicians will return
                            your nails to their former glory. </p>
                    </div>

                    <BookNowBtn/>
                </div>

                <div className={classes.imageRow}>
                    <img alt={'Intro'} src={images.nailImg03} className={classes.tallImg}/>

                    <div className={classes.wideImgContainer}>
                        <img alt={'Intro'} src={images.nailImg04} className={classes.wideImg}/>
                        <div className={classes.separator}></div>
                        <img alt={'Intro'} src={images.nailImg05} className={classes.wideImg}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Introduction02;
