import classes from "./FooterInfo.module.css";
import { CASTLE_NAIL_INFO } from "../../utils/Const";
import { images } from "../../assets/img";
import useCheckIsMobile from "../../hook/useCheckIsMobile";

const FooterInfo = (props) => {
  const isMobile = useCheckIsMobile();

  return (
    <div className={classes.container}>
      <div className={classes.columnInfo}>
        <div className={classes.address}>
          <h2>{"Castle Nails Bar"}</h2>
          <a
            target={"_blank"}
            href={CASTLE_NAIL_INFO.ggMapUrl}
            rel="noreferrer"
          >
            {`80, Castle nail Bar${isMobile ? "" : "\n"}Forfar DD8 3AA\n\n`}
          </a>
        </div>

        {/*TODO: REMOVE CHRISTMAS AFTER HOLIDAY FINISH*/}
        {/*<div style={{marginTop: 30,marginBottom: 30}} className={[classes.openingTimeWrapper]}>*/}
        {/*  <h3>Christmas Opening Time</h3>*/}
        {/*  <div className={classes.openingTimeContainer}>*/}
        {/*    <div className={classes.openingTime}>*/}
        {/*      <h3>{"Monday - Saturday"}</h3>*/}
        {/*      <h3>{"08:00 AM - 07:00 PM"}</h3>*/}
        {/*    </div>*/}
        {/*    <div className={classes.openingTime}>*/}
        {/*      <h3>Sunday</h3>*/}
        {/*      <h3>{"08:00 AM - 05:00 PM"}</h3>*/}
        {/*    </div>*/}
        {/*    <div className={classes.openingTime}>*/}
        {/*      <h3>{"15/12/2023"}</h3>*/}
        {/*      <h3>{"08:30 AM - 07:00 PM"}</h3>*/}
        {/*    </div>*/}
        {/*    <div className={classes.openingTime}>*/}
        {/*      <h3>{"01/01 - 02/01"}</h3>*/}
        {/*      <h3>{"Closed"}</h3>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div className={classes.openingTimeWrapper}>
          <h3>Opening Time</h3>
          <div className={classes.openingTimeContainer}>
            <div className={classes.openingTime}>
              <h3>{"Monday - Wednesday"}</h3>
              <h3>{"09:00 AM - 06:00 PM"}</h3>
            </div>
            <div className={classes.openingTime}>
              <h3>{"Thursday - Saturday"}</h3>
              <h3>{"08:30 AM - 06:30 PM"}</h3>
            </div>
            <div className={classes.openingTime}>
              <h3>Sunday</h3>
              <h3>Closed</h3>
            </div>
          </div>
        </div>

      </div>

      <div className={classes.contactContainer}>
        <h3>Contact Us</h3>
        <a
          href={`tel:${CASTLE_NAIL_INFO.tel}`}
        >{`Hotline: ${CASTLE_NAIL_INFO.phone}\n\n`}</a>
        <a
          href={`mailto:${CASTLE_NAIL_INFO.email}`}
        >{`Email: ${CASTLE_NAIL_INFO.email}`}</a>
      </div>

      <div className={classes.columnInfo}>
        <div className={`${classes.contactContainer} ${classes.marginBottom}`}>
          <h3>Follow Us</h3>
          <a
            target={"_blank"}
            href={CASTLE_NAIL_INFO.instagram}
            rel="noreferrer"
          >
            <img alt={"insta"} src={images.instaIcon} />
          </a>
          <a
            target={"_blank"}
            href={CASTLE_NAIL_INFO.facebook}
            rel="noreferrer"
          >
            <img alt={"insta"} src={images.fbIcon} />
          </a>
        </div>

        <br />

        <div
          className="fb-page"
          data-href={CASTLE_NAIL_INFO.facebook}
          data-tabs="timeline"
          data-width={isMobile ? "320" : "300"}
          data-height="100"
          data-small-header="false"
          data-adapt-container-width="false"
          data-hide-cover="false"
          data-show-facepile="true"
        >
          <blockquote
            cite={CASTLE_NAIL_INFO.facebook}
            className="fb-xfbml-parse-ignore"
          >
            <a href={CASTLE_NAIL_INFO.facebook}>Castle Nails Bar</a>
          </blockquote>
        </div>
      </div>
    </div>
  );
};

export default FooterInfo;
