export const images = {
    flagUk: require('./flag-uk.png'),
    flagVn: require('./flag-vn.png'),
    toothIcon: require('./tooth-icon.png'),
    fbIcon: require('./fb-icon.png'),
    castleNailIcon: require('./castle-nail-logo.png'),
    castleNailBanner: require('./castle-nail-banner.png'),
    instaIcon: require('./insta-icon.png'),
    nailService: require('./nail-service.png'),
    nailService2: require('./nail-service2.png'),
    nailService3: require('./nail-service3.png'),
    nailService4: require('./nail-service4.png'),
    leftArrow: require('./left-arrow.png'),
    nailImg01: require('./nailsaloninforfar3.jpg'),
    nailImg02: require('./nailartforfar.jpg'),
    nailImg03: require('./castlenailbarforfart.jpeg'),
    nailImg04: require('./NailHalloweenForfar.jpeg'),
    nailImg05: require('./nailshopforfar.jpg'),
    nailImg06: require('./nailImg06.jpg'),
    nailImg07: require('./nailImg07.jpg'),
    nailImg08: require('./nailImg08.jpg'),
    nailImg09: require('./nailImg09.jpg'),
    nailImg10: require('./nailImg10.jpg'),
    nailImg11: require('./nailImg11.jpg'),
    nailImg12: require('./nailImg12.jpg'),
    nailImg13: require('./nailImg13.jpg'),
    nailImg14: require('./nailImg14.jpg'),
    nailImg15: require('./nailImg15.png'),
};
