import React, {useContext, useEffect} from "react";
import classes from "./Services.module.css";
import AuthContext from "../../store/auth-context";
import Helpers from "../../utils/Helpers";
import {useNavigate} from "react-router-dom";
import {SERVICES} from "../../utils/Const";
import {images} from "../../assets/img";

const ServiceItem = (item, index, onPress) => {
    const {name, image, description} = item || {};
    return (
        <div
            onClick={onPress?.(item)}
            key={`${name + index}`}
            className={classes.serviceItem}
        >
            <div className={`${classes.roundDiv}`}>
                <img alt={'nail'} src={image}/>
            </div>

            <h3>{name}</h3>
            <h4 className={classes["change-color-on-hover"]}>{description}</h4>
        </div>
    );
};

const PricingItem = ({name, price, price2}) => {
    return (
        <>
            <div className={classes.pricing}>
                <h3 className={classes.alignLeft}>{name}</h3>
                <h3 className={classes.alignCenter}>{`£${price}`}</h3>
                {price2 ? (
                    <h3 className={classes.alignCenter}>{`${
                        price2 === " " ? "" : "£"}${price2}`}</h3>
                ) : null}
            </div>
        </>
    );
};

const PricingItem2 = ({items}) => {
    return (
        <div className={classes.rowItem}>
            {items.map((item) => {
                return (
                    <div key={item?.name} className={classes.pricingItem2}>
                        <h3 className={classes.alignLeft}>{item?.name}</h3>
                        <h3 className={classes.alignCenter}>{`${item?.isExtra ? 'extra' : ''} ${item?.price === " " ? "" : "£"}${item?.price}`}</h3>
                    </div>
                )
            })}
        </div>
    );
};


const Services = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
    }, []);

    const onPress = (item) => () => {
        // navigate(`/service-details/${item?.id}`, {state: {...item}});
    };

    return (
        <div ref={props?.serviceRef} className={classes.wrapper}>
            <h1>Our Services</h1>

            <div  className={classes.container}>
                {Helpers.isArrayAvailable(SERVICES)
                    ? SERVICES.map((item, index) => {
                        return ServiceItem(item, index, onPress);
                    })
                    : null}
            </div>

            <div className={classes.menuPricing}>
                <div className={classes.menuTitle}>
                    <h2 className={classes.alignLeft}>{SERVICES[0].name}</h2>
                    <h2 className={classes.alignCenter}>Full Set</h2>
                    <h2 className={classes.alignCenter}>Infill</h2>
                </div>
                <PricingItem
                    name={"Acrylic with Normal Polish"}
                    price={27}
                    price2={23}
                />
                <PricingItem name={"Acrylic with Shellac"} price={32} price2={27}/>
                <PricingItem name={"Ombre"} price={37} price2={32}/>
                <PricingItem name={"Glitter"} price={32} price2={27}/>
                <PricingItem name={"Pink & White"} price={37} price2={32}/>
                <PricingItem
                    name={"French Tip"}
                    price={"37 - £40"}
                    price2={"32 - £35"}
                />
                <PricingItem name={"Powder Gel"} price={35} price2={27}/>
                <PricingItem name={"BIAB Builder Gel"} price={32} price2={30}/>
                <PricingItem name={"BIAB on Natural Nail"} price={28} price2={25}/>
                <PricingItem name={"Acrylic on Toes"} price={40} price2={35}/>
                <PricingItem name={"Extra Shellac"} price={5} price2={" "}/>
            </div>

            <div className={classes.menuPricing}>
                <div className={classes.menuTitle}>
                    <h2 className={classes.alignLeft}>{SERVICES[1].name}</h2>
                </div>
                <PricingItem2
                    items={[{name: 'Shellac on Hands', price: 22}, {name: 'Shellac on Toes', price: 22}]}
                />
                <PricingItem2
                    items={[{name: 'Shellac with French Tips', price: 27}, {name: 'BIAB with Shellac', price: 28}]}
                />
            </div>

            <div className={classes.menuPricing}>
                <div className={classes.menuTitle}>
                    <h2 className={classes.alignLeft}>{SERVICES[2].name}</h2>
                </div>
                <PricingItem2
                    items={[{name: 'Manicure', price: 15}, {name: 'Manicure with Normal Polish', price: 17}]}
                />
                <PricingItem2
                    items={[{name: 'Manicure with Shellac', price: 27}, {name: 'Spa Pedicure', price: 25}]}
                />
                <PricingItem2
                    items={[{name: 'Spa Pedicure with Shellac', price: 32}, {
                        name: 'Spa Pedicure with Normal Polish',
                        price: 28
                    }]}
                />
                <PricingItem2
                    items={[{
                        name: 'Manicure + Spa Pedicure with Shellac',
                        price: 55
                    }, {name: 'Manicure + Spa Pedicure with Normal Polish', price: 43}]}
                />
                <PricingItem2
                    items={[{name: 'Extra French Tips', price: 5}, {name: ' ', price: ' '}]}
                />
            </div>


            <div className={`${classes.menuPricing} ${classes.noMargin}`}>
                <div className={classes.menuTitle}>
                    <h2 className={classes.alignLeft}>{SERVICES[3].name}</h2>
                </div>
                <PricingItem2
                    items={[{name: 'Take Off Gel', price: 7}, {name: 'Take Off Acrylic', price: 10}]}
                />
                <PricingItem2
                    items={[{name: 'Take Off & Polish', price: 27}, {name: 'Take Off & Manicure', price: 25}]}
                />
                <PricingItem2
                    items={[{name: 'Take Off & Shellac', price: 27}, {name: 'Nail Art Design', price: '5 - £20'}]}
                />
                <PricingItem2
                    items={[{name: 'Single Nail Repair', price: 3}, {name: 'Long Nail', price: 5}]}
                />
                <PricingItem2
                    items={[{name: 'Diamond (10pcs)', price: 5}, {name: 'Single Toe', price: '5'}]}
                />
                <PricingItem2
                    items={[{name: 'Chrome', price: '5', isExtra: true}, {
                        name: 'Holographic',
                        price: '5',
                        isExtra: true
                    }]}
                />
                <PricingItem2
                    items={[{name: 'Cat Eyes', price: '5', isExtra: true}, {name: ' ', price: ' '}]}
                />
            </div>

        </div>
    );
};

export default Services;
