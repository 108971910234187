import React from "react";
import classes from './BookNowBtn.module.css'
import {images} from "../../assets/img";
import {useNavigate} from "react-router-dom";

const BookNowBtn = (props) => {
    const navigate = useNavigate()
    const {isShowArrow=true}=props

    const onClickBookNow = () => {
        if (props?.onClick) {
            props?.onClick?.()
            return
        }
        navigate('/booking')
    }


    return (
        <button type={props?.type} className={classes.button}>
            <div onClick={onClickBookNow} className={`${classes.bookNowBtn} ${props?.style}`}>
                <h3 className={classes['change-color-on-hover']}>Book Now</h3>
                {isShowArrow ? <img alt={'right-arrow'} src={images.leftArrow}/> : null}
            </div>
        </button>

    );
};

export default BookNowBtn;
