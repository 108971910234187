import classes from "./Introduction.module.css";
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {images} from "../../assets/img";
import BookNowBtn from "../BookNowBtn";

//ABOUT US
const Introduction = (props) => {
    const {introductionRef} = props || {};

    return (
        <div ref={introductionRef} className={classes.introduction}>
            <div className={classes.introHeader}>
                <h2 className={classes.cursiveText}>Castle Nails Bar</h2>
            </div>
            <div className={classes.rowWrapper}>
            <img alt={'Intro'} src={images.nailImg15} className={classes.introImg}/>
            <div className={classes.introTextContainer}>
                <div>
                    <h1>We don't do drama, we do nails! </h1>
                    <h3 className={classes.greyIntroTextContainer}>Best Nails Salon In Angus for every nail treatment you love</h3>
                </div>
                <p className={classes.greyIntroTextContainer}>Enjoy your next nail adventure in our professional hands. Castle Nails Bar, located at Angus is proud to be called one of the best nail salons in Forfar. Choose from a variety of nail treatments, all designed to guarantee healthy fabulous nails and leave you with a smile on your face. Have trouble making up your mind? Our experienced team will be happy to advise you!</p>
                <p className={classes.greyIntroTextContainer}>Castle Nails Bar home to Forfar’s leading nail technicians. Proud to represent Forfar's best nail salon, our experienced team is eager to give you the nails you’ll love. From trusted classics such as a classic manicure to bespoke nail art. Leave it in our experienced hands and enjoy the best nail artistry in one of Forfar’s best nail salons. All the services you can imagine that can be found here.</p>
                <p className={classes.greyIntroTextContainer}>Bringing together the kind of experts and experience that you won’t find anywhere else, Castle Nails Bar is as a second house of every girls in Forfar, Angus. Discover our full list of services here.</p>
                <BookNowBtn/>
            </div>
            </div>
        </div>
    );
};

export default Introduction;
